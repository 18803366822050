<template>
    <div>
		<div class="header">
			<img class="logo-empresa" :src="reporteVenta.imagen" alt="logo"/>
			<span class="titulo-empresa">{{ reporteVenta.empresa }}</span>
			<span class="direccion">{{ reporteVenta.direccion }}</span>
			<span class="telefono">Telefono: {{ reporteVenta.telefono }}</span>
			<span class="email">Email: {{ reporteVenta.correo }}</span>
			<span class="ubicacion">Ciudad: {{ reporteVenta.ubicacion }}</span>

			<div class="textoborde">
				<p class="no-margin fechareporte"><strong>Fecha Reporte: </strong> {{ reporteVenta.fechaReporte }}</p>
				<p class="no-margin horareporte"><strong>Hora Reporte: </strong> {{ reporteVenta.horaReporte }}</p>
				<p class="no-margin usuario"><strong>Usuario: </strong> {{ reporteVenta.usuario }}</p>
				<p class="no-margin almacen"><strong>{{ reporteVenta.almacen }}</strong></p>
			</div>

			<span class="documento">{{ reporteVenta.documento }}</span>
			<span class="documentoReporte">del {{ reporteVenta.fecha_inicio }} al {{ reporteVenta.fecha_final }}</span>
		</div>

		<div class="footer">
			<center>
				<div class="css-no_print" align="center">
					<a-button onclick="print_default_printer()" type="primary">
						<a-icon type="printer" /> IMPRIMIR REPORTE
					</a-button>
				</div>
			</center>
		</div>

		<table>
			<thead><tr><th><div class="t-head">&nbsp;</div></th></tr></thead>
			<tbody><tr><td><div class="content">

				<table class="table table-condensed table-striped">
					<thead>
						<tr>
							<th style="width: 10%; font-size:11px;">CODIGO</th>
							<th style="width: 15%; font-size:11px;">FECHA</th>
							<th style="width: 10%; font-size:11px;">ESTADO</th>
							<th style="width: 15%; font-size:11px;">FORMA DE PAGO</th>
							<th style="width: 8%; font-size:11px; text-align: center;">EFECTIVO</th>
							<th style="width: 8%; font-size:11px; text-align: center;">CAMBIO</th>
							<th style="width: 8%; font-size:11px; text-align: center;">SUBTOTAL</th>
							<th style="width: 8%; font-size:11px; text-align: center;">DESC</th>
							<th style="width: 8%; font-size:11px; text-align: center;">TOTAL</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in reporteVenta.ventas" :key="index">
							<td style="width: 10%;" class="tabla-listado">{{ item.codigoVenta }}</td>
							<td style="width: 15%;" class="tabla-listado">{{ item.fechaVenta }} {{ item.horaVenta }}</td>
							<td style="width: 10%;" class="tabla-listado">{{ item.estado }}</td>
							<td style="width: 15%;" class="tabla-listado">{{ item.plancuenta.nombre }}</td>
							<td style="width: 8%; text-align: center;" class="tabla-listado">{{ item.efectivo }}</td>
							<td style="width: 8%; text-align: center;" class="tabla-listado">{{ item.cambio }}</td>
							<td style="width: 8%; text-align: center;" class="tabla-listado">{{ item.montoTotal }}</td>
							<td style="width: 8%; text-align: center;" class="tabla-listado">{{ item.descuento }}</td>
							<td style="width: 8%; text-align: center;" class="tabla-listado">{{ item.totalVenta }}</td>
						</tr>
					</tbody>
				</table>
        
				<table>
					<tbody>
						<tr>
							<td></td>
							<td style="width: 10%; color:black; font-weight: 700;">Subtotal:</td>
							<td style="width: 13%; text-align: right;">{{ reporteVenta.montoTotal }}</td>
						</tr>
						<tr>
							<td><span style="color:black; font-weight: 700;">Son:</span> <span style="font-size:13px;">{{ reporteVenta.literal }}</span></td>
							<td style="width: 10%; color:black; font-weight: 700;">Descuento:</td>
							<td style="width: 13%; text-align: right;">{{ reporteVenta.descuento }}</td>
						</tr>
						<tr>
							<td></td>
							<td style="width: 10%; color:black; font-weight: 700;">Total:</td>
							<td style="width: 13%; text-align: right; color:black; font-weight: 700;">{{ reporteVenta.totalVenta }}</td>
						</tr>
					</tbody>
				</table>

				<center><span style="font-weight:400; font-size:10px; color:black;">Reporte generado por www.efectivo.io </span></center>
			</div></td></tr></tbody>
			<tfoot><tr><td><div class="t-foot">&nbsp;</div></td></tr></tfoot>
		</table>
    </div>
</template>

<script>
  export default {
      props: {
          reporteVenta: { type: Object, required: true }
      }
  }
</script>

<style scoped>
	@page {
		margin: 0mm;
		size: 218mm; 
		size: portrait;
	}

	@media print {
		body {border: 0;}
		thead {display: table-header-group;} 
		tfoot {display: table-footer-group;}
		button {display: none;}
		body {margin: 0;}
	}

	body {
		margin: 0 auto;
		font-family: 'Space Mono', monospace;
		max-width: 800px;
		background-color: #525252;
		width: 100%;
		border: 1px solid #ffff;
	}

	table {
        width: 100%;
    }

	.tabla-listado {
		background-color: #ffffff; 
		border-top: 0px solid #ffffff; 
		padding: 5px; 
		border-bottom: 1px solid rgb(180, 180, 180);
		font-size: 10px;
	}
	.header, .t-head, .footer, .t-foot {
		height: 100px; max-width: 803px; width: 100%;
		background: #fff;
	}

	.footer, .t-foot { height: 50px; }
	.t-head, .t-foot { background: #fff; }
	.pr {
	position: fixed; right: 0; bottom: 10px;
	color: #aaa; letter-spacing: 1px;
	font-weight: normal; text-decoration: none;
	}

	.header{
		background: #fcfcfc;
		position: fixed; 
		top: 0;
		border-bottom: 1px solid #ffff;
	}
	.footer {
		background: #fcfcfc;
		position: fixed; 
		bottom: 0;
		border-top: 1px solid #ffff;
	}
	.content {
		background: #fefefe;
		padding: 10px;
	}

	.content .page {
		page-break-after: always;
	}
	.logo-empresa{
		position:absolute;
		width:80px;
		height:auto;
		left : 10px;
		z-index: 1;
		top:10px;
	}
	.titulo-empresa{
		position:absolute;
		font-size: 11px;
		color:black;
		font-weight:700;
		letter-spacing: 0.50;
		left : 97px;
		z-index: 1;
		top: 12px;
	}

	.direccion{
		position:absolute;
		font-size: 9px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 28px;
	}
	.telefono{
		position:absolute;
		font-size: 8px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 42px;
	}

	.celular{
		position:absolute;
		font-size: 8px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 56px;
	}
	.email{
		position:absolute;
		font-size: 8px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 55px;
	}

	.ubicacion{
		position:absolute;
		font-size: 9px;
		font-weight:400;
		color:black;
		left : 97px;
		z-index: 1;
		top: 68px;
	}
	.textoborde {
		position:absolute;
		width: 170px;
		height: 70px;
		background-color:#F5F5F5;
		border:1.2px solid #DDDDDD;  
		text-align: right;
		line-height : 1px;
		overflow:hidden;
		padding:5px;
		-webkit-border-radius: 4px;
		border-radius: 4px;
		left : 590px;
		z-index: 1;
		top:20px;
		padding-top: 5px;
		padding-right: 5px;
		padding-bottom: 0px;
		padding-left: 5px;
	}

	.fechareporte{
		position:absolute;
		font-size: 10px;
		font-weight:400;
		color:black;
		left : 6px;
		z-index: 1;
		top: 10px;
	}
	.horareporte{
		position:absolute;
		font-size: 10px;
		font-weight:400;
		color:black;
		left : 6px;
		z-index: 1;
		top: 23px;
	}

	.usuario{
		position:absolute;
		font-size: 10px;
		font-weight:400;
		color:black;
		left : 6px;
		z-index: 1;
		top: 38px;
	}
	.almacen{
		position:absolute;
		font-size: 10px;
		font-weight:400;
		color:black;
		left : 6px;
		z-index: 1;
		top: 53px;
	}
	.css-data_table{
		width: 100%;
		border: none;
		border-collapse: collapse;
		border-spacing: 2px 0px;
	}
				
	.css-data_table th {
		border-bottom: 1px solid rgb(14, 14, 14);      
		font-weight: 700;
		text-align: center;
		font-size: 0.9em;
		font-weight:700;
	}
	.css-data_table td{
		border: 1px solid black;
		padding: 5px;
		text-align: center;
		font-size: 0.6em;
		font-weight:400;
	}
	.documento{
		position:absolute;
		font-size: 16px;
		font-weight:700;
		color:black;
		left : 300px;
		z-index: 1;
		top: 50px;
	}
	.documentoReporte{
		position:absolute;
		font-size: 15px;
		font-weight:700;
		color:black;
		left : 288px;
		z-index: 1;
		top: 75px;
	}
	.fecha{
		position:absolute;
		font-size: 13px;
		font-weight:40;
		color:black;
		left : 320px;
		z-index: 1;
		top: 60px;
	}
</style>